import i18n from '@/libs/i18n';
import moment from 'moment';
import Vue from 'vue';

import flagEs from '@/assets/images/flags/es.png';
import flagCa from '@/assets/images/flags/ca.png';
import flagEn from '@/assets/images/flags/en.png';

export default {

  state: {
    currentLocale: 'es',
  },
  getters: {
    currentLocale: (state) => (state.currentLocale),
    locales: () => [
      {
        value: 'es',
        img: flagEs,
        text: 'Español',
      },
      {
        value: 'ca',
        img: flagCa,
        text: 'Català',
      },
      {
        value: 'en',
        img: flagEn,
        text: 'English',
      },
    ],
  },
  mutations: {
    SET_LOCALE(state, newLocale) {
      state.currentLocale = newLocale;
    },
  },
  actions: {
    async setLocale({ commit }, newLocale) {
      commit('SET_LOCALE', newLocale);
      i18n.locale = newLocale;
      Vue.$cookies.set("i18n_redirected", i18n.locale)
      moment.locale(newLocale);
    },
  },

};
