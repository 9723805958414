// import dependency to handle HTTP request to our back end
import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import Service from '@/config/service-identifiers';
import $services from '@/config/container';

export default {
  state: {
    chats: {},
    globalChat: {
      paginated: {},
      unpaginated: [],
    },
  },
  getters: {
    chats: (state) => state.chats,
    globalChat: (state) => state.globalChat,
  },
  mutations: {
    SET_ITEMS_SOCKETS(state, payload) {
      if (payload.storedKey == null) {
        // En caso de que no sea un fragmentado guardaremos el paginado y el no paginado junto al meta.
        Vue.set(
          state[payload.type].paginated, 1,
          [...payload.data.data],
        );

        state[payload.type].unpaginated = [...state[payload.type].unpaginated, ...payload.data.data];
        Vue.set(state[payload.type], 'meta', { ...payload.data.meta });
      } else {
        // En caso de que sea un fragmentado comprobamos si existe.
        if (state[payload.type][payload.storedKey] == null) {
          // En caso de no existir se inicializa.
          Vue.set(state[payload.type], payload.storedKey, { unpaginated: {} });
        }
        if (payload.force === true) {
          Vue.set(state[payload.type][payload.storedKey], 'unpaginated', []);
        }
        // En caso de existir no hace falta inicializarlo, sigue adelante.
        if (state[payload.type][payload.storedKey].unpaginated.length == null) {
          if (payload.data.data instanceof Array) {
            state[payload.type][payload.storedKey].unpaginated = [...payload.data.data];
            Vue.set(state[payload.type][payload.storedKey], 'unpaginated', [...payload.data.data]);
          } else {
            state[payload.type][payload.storedKey].unpaginated = [payload.data.data];
            Vue.set(state[payload.type][payload.storedKey], 'unpaginated', [payload.data.data]);
          }
        } else if (payload.data.data instanceof Array) {
          Vue.set(state[payload.type][payload.storedKey], 'unpaginated', [...state[payload.type][payload.storedKey].unpaginated, ...payload.data.data]);
        } else {
          Vue.set(state[payload.type][payload.storedKey], 'unpaginated', [...state[payload.type][payload.storedKey].unpaginated, payload.data.data]);
        }
        Vue.set(state[payload.type][payload.storedKey], 'meta', payload.data.data);
      }
    },
  },
  actions: {
    getItemsSocket({ commit, state, rootState }, {
      itemType, perPage, requestConfig, storedKey,
    }) {
      return new Promise((resolve) => {
        const communitySlug = rootState.collective.collective.slug;

        $services[Service.Sockets]
          .get(`/api/v1/${itemType}`, {
            params: {
              communitySlug: communitySlug,
              count: perPage,
              ...requestConfig,
            },
          })
          .then((response) => {
            commit('SET_ITEMS_SOCKETS', {
              type: itemType, storedKey, data: response.data, force: true,
            });
            resolve(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    setItemSocket({ commit, state, rootState }, { itemType, item, storedKey }) {
      commit('SET_ITEMS_SOCKETS', {
        type: itemType, storedKey, data: { data: item },
      });

      return item;
    },
  },
};
